body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.editable-profile-field:focus-visible {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/Helvetica Neue/HelveticaNeue-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/Helvetica Neue/HelveticaNeue.otf") format("opentype");
  font-weight: normal;
}
